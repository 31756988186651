// page-top

$(function(){
  var topBtn=$('#page-top');
  topBtn.hide();
  $(window).scroll(function(){
    if($(this).scrollTop()>80){
      topBtn.fadeIn();
    } else {
      topBtn.fadeOut();
    }
  });
  topBtn.click(function(){
    $('body,html').animate({
    scrollTop: 0},500);
    return false;
  });
});


// 外部リンクを別タブで開く

$(function() {
  $("a[href^='http://']").attr("target","_blank");
});


// swiper
$(function() {
    var swiper = new Swiper('.swiper-container', {
        pagination: '.swiper-pagination',
        paginationClickable: true,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: 5000
    });
})

$(document).ready(function() {
  $('.image-link').magnificPopup({type:'image'});
});


// コース紹介 開閉

$(function() {
  var windowSize = 'pc';
  var sizeChanged = true;
  var devices = {
    small: {width: 768}
  };

  $(window).resize(function(){
    if (windowSize !== 'tb' && $(window).width() <= devices.small.width) {
      windowSize = 'tb';
      sizeChanged = true;
    } else if (windowSize !== 'pc' && $(window).width() > devices.small.width) {
      windowSize = 'pc';
      sizeChanged = true;
    }

    if (sizeChanged === true) {
      $('img[data-src], input[data-src]').each(function() {
        $(this).prop('src', $(this).data('src').replace(/SIZE/, windowSize));
      });

      if(windowSize == 'tb') {

        $('.tb-menu').hide();
        $('.gNav .course').unbind();
        $('.tb-menu-btn').click(function(){
          $(this).next('.tb-menu').slideToggle();
          $(this).toggleClass('is-opened');
        });
        $(document).on('click touchstart',function(){
          $('.tb-menu').hide();
          $('.tb-menu-btn').removeClass('is-opened');
        });
        $('.tb-menu-btn').on('click touchstart',function(){
          event.stopPropagation();
        });
        $('.tb-menu').on('click touchstart',function(){
          event.stopPropagation();
        });

      } else {

        $('.tb-menu').show();
        $('.gNav .course').click(function(){
           $(this).children('ul').slideToggle();
        });
        $(document).on('click touchstart',function(){
          $('.tb-menu').show();
        });
      }

      sizeChanged = false;
    }
  }).trigger('resize');
});
